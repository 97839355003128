<template>
  <div class="card">
    <div class="card-body">
      <h4
        class="card-title"
        :class="
          $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : 'txt-left'
        "
      >
        {{ $t("app.weeks") }}
        <p></p>
      </h4>
      <div
        v-for="(week, idx) in row.weekDays"
        :key="idx"
        class="col-12 pd-0"
        :class="$i18n.locale == 'ar' ? '' : 'txt-left'"
        style="padding: 0"
      >
        <input
          class=""
          type="checkbox"
          :value="week['status']"
          :checked="week['status']"
          v-on:input="onChange(idx)"
        />
        <label
          :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
        >
          &nbsp; {{ week["value"] }} &nbsp;
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardWeekDays",
  props: ["weekDays"],
  data() {
    return {
      row: {
        weekDays: this.$props.weekDays,
        selected: {},
      },
    };
  },
  mounted() {},
  methods: {
    //
    onChange(id) {
      // get status
      if (this.row.weekDays[id]["status"]) {
        this.row.weekDays[id]["status"] = false;
      } else {
        this.row.weekDays[id]["status"] = true;
      }

      for (let i = 0; i < this.row.weekDays.length; i++) {
        this.row.selected[i] = {
          key: this.row.weekDays[i]["key"],
          value: this.row.weekDays[i]["value"],
          status: this.row.weekDays[i]["status"],
        };
      }

      let data = {
        weekDays: this.row.selected,
      };

      this.$emit("weekDaysChange", data);
    },
  },
};
</script>
